// import axios from "axios";

const state = {
  selectedProduct: null,
  editedProduct: null,
  cart: [],
  inclusive: {
    status: false,
    taxes: [],
  },
  delivery: [],

  voucher: null,
  editCartIndex: null,
  selectedHistory: null,
  scrollPosition: 0,
};

const getters = {
  getSelectedProduct: (state) => state.selectedProduct,
  getInclusive: (state) => state.inclusive,
  getCart: (state) => state.cart,
  getDelivery: (state) => state.delivery,
  getVoucher: (state) => state.voucher,
  getSelectedHistory: (state) => state.selectedHistory,
  getEditCartIndex: (state) => state.editCartIndex,
  getScrollPosition: (state) => state.scrollPosition,
};

const actions = {
  setEditCartIndex({ state }, value) {
    state.editCartIndex = value;
  },
  updateSP({ state }, value) {
    state.selectedProduct = value;
  },
  editSP({ state }, value) {
    value.value.newProd = 1;
    state.selectedProduct = value.value;
    state.editedProduct = value;
  },
  updateSH({ state }, value) {
    state.selectedHistory = value;
  },
  removeSH({ state }) {
    state.selectedHistory = null;
  },
  plus({ state }, v) {
    state.cart[v].quantity++;
  },

  updateCart({ state }, value) {
    state.cart = value;
  },

  removeCart({ state }, value) {
    localStorage.removeItem("cart_list");
    const newCartValue = [
      ...state.cart.slice(0, value),
      ...state.cart.slice(value + 1, state.cart.length),
    ];
    state.cart = newCartValue;
    localStorage.setItem("cart_list", JSON.stringify(newCartValue));
  },

  findVoucher({ dispatch, state }, value) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/vouchers/${value.voucher}`,
        method: "get",
      }).then((response) => {
        if (!response.data.error) {
          response.data.min = response.data.min || 0;
          if (value.total >= response.data.min) {
            state.voucher = response.data;
            state.voucher.id = response.data._id;
          } else {
            state.voucher = null;
          }
        }
        res(response.data);
      });
    });
  },

  cartTotal({ state }) {
    return new Promise((res) => {
      let total = state.cart.reduce((sum, item) => {
        if (item.taxes && item.taxes.length > 0) {
          state.inclusive.taxes = item.taxes;
          state.inclusive.status = item.taxes[0].inclusive;
        }

        let itemTotal = 0;
        // calculated variant
        if (item.variant[0]) {
          itemTotal = item.variant[0].price * item.quantity;
        } else {
          itemTotal = parseFloat(item.unit_price) * item.quantity;
        }

        // calculated modifier
        let mods = item.modifiers.reduce((sum, mod) => {
          let amt = parseFloat(mod.amount);
          let actual = mod.mode == 1 ? amt : mod.mode == 2 ? -amt : 0;
          return sum + actual;
        }, 0);

        itemTotal += mods;
        return sum + itemTotal;
      }, 0);

      res(total);
    });
  },
  fetchOwnDelivery({ state, rootGetters }) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      let push = () => {
        let a = state.delivery.findIndex((d) => d.provider == "own_delivery");
        if (a == -1) {
          state.delivery.push({
            name: "Delivery",
            provider: "own_delivery",
            data:
              {
                delivery_fee: f.delivery_fees || 0,
              } || null,
          });
        } else {
          state.delivery[a].data =
            {
              delivery_fee: f.delivery_fees || 0,
            } || null;
        }
      };
      push();
      res(true);
    });
  },

  async fetchDelyvaFees({ state, dispatch, rootGetters }, cust) {
    let f = rootGetters["getFranchise"];
    let o = rootGetters["getSelectedOutlet"];

    let oGeo = f.outlets.find((d) => d.name == o);

    let mercGeo = {
      address: oGeo ? oGeo.address : f.address,
      address2: oGeo ? oGeo.address2 : f.address2,
    };
    let fGeo = await dispatch("fetchGeocode", mercGeo);

    let custGeo = {
      address: cust.address,
      address2: cust.address2,
    };
    let cGeo = await dispatch("fetchGeocode", custGeo);

    let weight = state.cart.reduce(function (sum, item) {
      if (item.weight) {
        return (sum = sum + item.weight.converted * item.quantity);
      } else {
        return (sum = sum + 0.5 * item.quantity);
      }
    }, 0);

    new Promise((res) => {
      var s = {
        pickup_address1: `${fGeo.address}`,
        pickup_address2: `${fGeo.address2}`,
        dropoff_address1: `${cust.address}`,
        dropoff_address2: `${cust.address2}`,
        pickup_city: `${fGeo.city}`,
        pickup_state: `${fGeo.state}`,
        pickup_pc: `${oGeo ? oGeo.postcode : f.postcode}`,
        dropoff_city: `${cGeo.city}`,
        dropoff_state: `${cGeo.state}`,
        dropoff_pc: `${cust.postcode}`,
        pickup_long: fGeo.longitude,
        pickup_lat: fGeo.latitude,
        dropoff_long: cust.lng ? cust.lng : cGeo.longitude,
        dropoff_lat: cust.lat ? cust.lat : cGeo.latitude,
        fire_id: f.fire_id,
        total_weight: weight,
      };

      dispatch("fetch", {
        url: "/guest/delyvax/calculate",
        method: "post",
        data: s,
      }).then((r) => {
        let push = (r) => {
          if (r.data.data.services.length != 0) {
            var p = r.data.data.services;
            for (let [key, value] of Object.entries(p)) {
              let r = { delivery_fee: value.price.amount };
              let a = state.delivery.findIndex(
                (d) => d.name == value.service.name
              );
              if (a == -1) {
                state.delivery.push({
                  name: value.service.name,
                  provider: "delyvax",
                  code: value.service.code,
                  data: r || null,
                  key: key,
                });
              } else {
                state.delivery[a].data = r || null;
              }
            }
          } else {
            let c = state.delivery.findIndex((d) => d.name == "unavailable");
            if (c == -1) {
              state.delivery.push({
                name: "unavailable", //check name
                provider: "delyvax", //check provider
              });
            } else {
              state.delivery;
            }
          }
        };
        push(r);
        res(r.data);
      });
    });
  },

  fetchSpeedyFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "speedy");

          if (a == -1) {
            state.delivery.push({
              name: "MrSpeedy Delivery",
              provider: "speedy",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "MrSpeedy Delivery",
            provider: "speedy",
            data: r || null,
          });
      };

      push();
      var s = value;
      s.coords = [];
      dispatch("fetch", {
        url: "/guest/calculate",
        method: "post",
        data: { guest: s },
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }

        push(r.data);
        res(r.data);
      });
    });
  },

  fetchBungkusitFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "bungkusit");
          if (a == -1) {
            state.delivery.push({
              name: "Bungkusit Delivery",
              provider: "bungkusit",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "Bungkusit Delivery",
            provider: "bungkusit",
            data: r || null,
          });
      };
      push();
      var s = {
        pickup_address: `${f.address}, ${f.address2}`,
        dropoff_address: `${value.address}, ${value.address2}`,
        order_type: "DELIVER_NOW",
        pickup_long: f.longitude,
        pickup_lat: f.latitude,
        dropoff_long: value.longitude,
        dropoff_lat: value.latitude,
        fire_id: f.fire_id,
        cust_name: value.name,
        cust_phone: value.phone,
        notes: "",
      };
      dispatch("fetch", {
        url: "/guest/bungkusit/calculate",
        method: "post",
        data: s,
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }
        push(r.data);
        res(r.data);
      });
    });
  },

  fetchLalamoveFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "lalamove");
          if (a == -1) {
            state.delivery.push({
              name: "Lalamove Delivery",
              provider: "lalamove",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "Lalamove Delivery",
            provider: "lalamove",
            data: r || null,
          });
      };
      push();
      var s = {
        pickup_address: `${f.address}, ${f.address2}`,
        dropoff_address: `${value.address}, ${value.address2}`,
        order_type: "DELIVER_NOW",
        pickup_long: f.longitude,
        pickup_lat: f.latitude,
        dropoff_long: value.longitude,
        dropoff_lat: value.latitude,
        fire_id: f.fire_id,
        cust_name: value.name,
        cust_phone: value.phone,
        notes: "",
      };
      dispatch("fetch", {
        url: "/guest/lalamove/calculate",
        method: "post",
        data: s,
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }
        push(r.data);
        res(r.data);
      });
    });
  },
};

const mutations = {
  addToCart(state, payload) {
    const fromEditCart = payload.from == "edit_cart";
    const value = fromEditCart ? payload.value : payload;
    if (fromEditCart) {
      state.cart = state.cart.map((item, index) => {
        if (index === state.editCartIndex) {
          return value;
        }
        return item;
      });
      state.editCartIndex = null;
      return;
    }
    function isEqualModifier(arr1, arr2) {
      // compare modifiers from to array, to deside whether we need
      // to add new value to cart or modify the existing product in the cart
      if (arr1.length !== arr2.length) {
        return false;
      }

      arr1.sort((a, b) => a.id - b.id);
      arr2.sort((a, b) => a.id - b.id);

      for (let i = 0; i < arr1.length; i++) {
        const el1 = arr1[i];
        const el2 = arr2[i];

        for (const prop in el1) {
          if (el1[prop] !== el2[prop]) {
            return false;
          }
        }
      }

      return true;
    }
    let parsedState = JSON.parse(JSON.stringify(state));
    let parsedValue = JSON.parse(JSON.stringify(value));

    if (parsedState.cart.length == 0) {
      state.cart.push(value);
      addToLocalStorage(state.cart);
    } else {
      let isProductExist = false;
      let indexProductByVariant = null;
      let indexProductByModifier = null;
      let shouldAddNewProduct = true;
      for (let i = 0; i < parsedState.cart.length; i++) {
        const product = parsedState.cart[i];
        if (product.id == parsedValue.id) {
          isProductExist = true;
          // variant logic combine with modifier
          if (
            parsedValue.variant.length > 0 &&
            product.variant.length > 0 &&
            product.variant[0].id == parsedValue.variant[0].id
          ) {
            if (isEqualModifier(parsedValue.modifiers, product.modifiers)) {
              shouldAddNewProduct = false;
              indexProductByVariant = i;
              break;
            }
          }
          // modifier logic combine with variant
          if (
            product.variant.length > 0 &&
            product.variant[0].id == parsedValue.variant[0].id &&
            parsedValue.modifiers.length > 0 &&
            isEqualModifier(parsedValue.modifiers, product.modifiers)
          ) {
            shouldAddNewProduct = false;
            indexProductByModifier = i;
            break;
          }
          if (
            product.variant.length == 0 &&
            parsedValue.modifiers.length > 0 &&
            isEqualModifier(parsedValue.modifiers, product.modifiers)
          ) {
            shouldAddNewProduct = false;
            indexProductByModifier = i;
            break;
          }
        }
      }
      if (!isProductExist) {
        state.cart.push(value);
        addToLocalStorage(state.cart);
        return;
      } else {
        if (shouldAddNewProduct) {
          state.cart.push(value);
          addToLocalStorage(state.cart);
          return;
        } else {
          if (indexProductByVariant != null) {
            state.cart = state.cart.map((item, index) => {
              if (index === indexProductByVariant) {
                return {
                  ...item,
                  quantity: item.quantity + 1,
                };
              }
              return item;
            });
            // state.cart[indexProductByVariant].quantity ++;
          } else if (indexProductByModifier != null) {
            state.cart = state.cart.map((item, index) => {
              if (index === indexProductByModifier) {
                return {
                  ...item,
                  quantity: item.quantity + 1,
                };
              }
              return item;
            });
            // state.cart[indexProductByModifier].quantity ++;
          }
          addToLocalStorage(state.cart);
          return;
        }
      }
    }
    function addToLocalStorage(item) {
      window.localStorage.setItem("cart_list", JSON.stringify(item));
    }
  },
  setScrollPosition(state, position) {
    state.scrollPosition = position;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
